import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getClient, updateClient, createDevice, fetchDevices } from '../services/api';
import AddCreditPopup from './AddCreditPopup';

const FormContainer = styled.div`
  padding: 20px;
  margin: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin-left: 20px; /* Align to the left with a margin */
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
`;

const Tab = styled.button`
  background-color: transparent;
  color: ${(props) => (props.active ? '#343a40' : '#6c757d')};
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: ${(props) => (props.active ? '2px solid #343a40' : 'none')};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  &:hover {
    color: #343a40;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; /* Gap between grid items */
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Add gap between form rows */
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  font-size: 14px;
`;

const FullWidthInput = styled(Input)`
  grid-column: span 2;
`;

const ReadOnlyInput = styled(Input)`
  background-color: #e9ecef;
  cursor: not-allowed;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #343a40;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px; /* Add margin below button */
  &:hover {
    background-color: #495057;
  }
  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

const StatusCircle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? 'green' : 'gray')};
  margin: auto;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #343a40;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
  margin: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: #343a40;
  color: #fff;
  border: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
`;

const ConfirmPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const ClientDetails = () => {
  const { clientId } = useParams();
  const [clientData, setClientData] = useState({
    id: '',
    name: '',
    initial: '',
    subDomain: '',
    superAdminFirstName: '',
    superAdminLastName: '',
    superAdminEmail: '',
    credits: 0,
    isActive: false,
  });
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [activeTab, setActiveTab] = useState('Details');
  const [showConfirmPopup, setShowConfirmPopup] = useState(false); // State for confirmation popup
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await getClient(clientId);
        setClientData(response.data);
      } catch (error) {
        toast.error(error.response?.data?.displayMessage ?? 'Something Went Wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [clientId]);

  useEffect(() => {
    const fetchClientDevices = async () => {
      if (activeTab === 'Devices') {
        setLoading(true);
        try {
          const response = await fetchDevices(clientId);
          setDevices(response);
        } catch (error) {
          toast.error(error.message ?? 'Failed to fetch devices');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchClientDevices();
  }, [clientId, activeTab]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdating(true);

    try {
      await updateClient(clientId, clientData);
      toast.success('Client updated successfully');
      navigate('/clients');
    } catch (error) {
      toast.error(error.response?.data?.displayMessage ?? 'Something Went Wrong');
    } finally {
      setUpdating(false);
    }
  };

  const handleAddCredit = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleAddDevice = () => {
    setShowConfirmPopup(true);
  };

  const handleConfirmAddDevice = async () => {
    try {
      await createDevice({ clientId });
      toast.success('Device created successfully');
      // Refetch devices after adding new one
      const response = await fetchDevices(clientId);
      setDevices(response);
    } catch (error) {
      toast.error(error.response?.data?.displayMessage ?? 'Something Went Wrong');
    } finally {
      setShowConfirmPopup(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <FormContainer>
      <ToastContainer />
      <Tabs>
        <Tab active={activeTab === 'Details'} onClick={() => setActiveTab('Details')}>Details</Tab>
        <Tab active={activeTab === 'Devices'} onClick={() => setActiveTab('Devices')}>Devices</Tab>
      </Tabs>
      {activeTab === 'Details' && (
        <Form onSubmit={handleSubmit}>
          <FormRow>
            <Label>Client ID</Label>
            <ReadOnlyInput
              type="text"
              name="id"
              value={clientData.id}
              readOnly
            />
          </FormRow>
          <FormRow>
            <Label>Client Name</Label>
            <Input
              type="text"
              name="name"
              value={clientData.name}
              onChange={handleChange}
              required
            />
          </FormRow>
          <FormRow>
            <Label>Client Initial</Label>
            <Input
              type="text"
              name="initial"
              value={clientData.initial}
              onChange={handleChange}
              required
              minLength="3"
              maxLength="3"
              pattern=".{3}"
              title="Initial must be exactly 3 characters"
            />
          </FormRow>
          <FormRow>
            <Label>Subdomain</Label>
            <ReadOnlyInput
              type="text"
              name="subDomain"
              value={clientData.subDomain}
              readOnly
            />
          </FormRow>
          <FormRow>
            <Label>Admin First Name</Label>
            <Input
              type="text"
              name="superAdminFirstName"
              value={clientData.superAdminFirstName}
              onChange={handleChange}
              required
            />
          </FormRow>
          <FormRow>
            <Label>Admin Last Name</Label>
            <Input
              type="text"
              name="superAdminLastName"
              value={clientData.superAdminLastName}
              onChange={handleChange}
              required
            />
          </FormRow>
          <FormRow>
            <Label>Admin Email</Label>
            <Input
              type="email"
              name="superAdminEmail"
              value={clientData.superAdminEmail}
              onChange={handleChange}
              required
            />
          </FormRow>
          <FormRow>
            <Label>Credits</Label>
            <Input
              type="number"
              name="credits"
              value={clientData.credits}
              onChange={handleChange}
              required
            />
          </FormRow>
          <FormRow>
            <Label>Active</Label>
            <Input
              type="checkbox"
              name="isActive"
              checked={clientData.isActive}
              onChange={(e) =>
                setClientData((prevData) => ({
                  ...prevData,
                  isActive: e.target.checked,
                }))
              }
            />
          </FormRow>
          <Button type="submit" disabled={updating}>
            {updating ? <Loader /> : 'Update Client'}
          </Button>
          <Button type="button" onClick={handleAddCredit}>
            Add Credit
          </Button>
        </Form>
      )}
      {activeTab === 'Devices' && (
        <>
          <ButtonContainer>
            <Button type="button" onClick={handleAddDevice}>
              Add New Device
            </Button>
          </ButtonContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>Device ID</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader>Created At</TableHeader>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) => (
                <tr key={device._id}>
                  <TableCell>{device.deviceId}</TableCell>
                  <TableCell>
                  <StatusCircle isActive={device.isActive} />
                </TableCell>
                  <TableCell>{formatDate(device.createdAt)}</TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {showPopup && <AddCreditPopup clientId={clientId} onClose={closePopup} />}
      {showConfirmPopup && (
        <>
          <PopupOverlay onClick={() => setShowConfirmPopup(false)} />
          <ConfirmPopup>
            <p>Are you sure you want to create a new device?</p>
            <Button onClick={handleConfirmAddDevice}>Yes</Button>
            <Button onClick={() => setShowConfirmPopup(false)}>No</Button>
          </ConfirmPopup>
        </>
      )}
    </FormContainer>
  );
};

export default ClientDetails;
