// src/components/navbar.js
import React from 'react';
import styled from 'styled-components';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;  // Center align items vertically
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WelcomeText = styled.div`
  margin-right: 10px;
  font-size: 16px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Navbar = () => (
  <NavbarContainer>
    <div>Welcome Vijin</div>
    <ProfileContainer>
      <WelcomeText>Vijin</WelcomeText>
      <ProfileImage src="https://via.placeholder.com/40" alt="User Profile" />
    </ProfileContainer>
  </NavbarContainer>
);

export default Navbar;
