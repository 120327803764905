import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaUsers, FaSignOutAlt, FaHome } from 'react-icons/fa'; // Import FaHome for the dashboard icon

const SidebarContainer = styled.div`
  width: 250px;
  background-color: #343a40;
  height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;  // Add padding to the bottom
`;

const SidebarHeader = styled.h2`
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
  padding: 10px;
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const NavItem = styled.li`
  padding: 10px;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    color: #adb5bd;
  }

  svg {
    margin-right: 10px;
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 16px;

  &:hover {
    color: #adb5bd;
  }

  svg {
    margin-right: 10px;
  }
`;

const Sidebar = ({ onLogout }) => (
  <SidebarContainer>
    <div>
      <SidebarHeader>SEAL Admin</SidebarHeader>
      <nav>
        <NavList>
          <NavItem>
            <NavLink to="/"><FaHome /> Dashboard</NavLink> {/* Link to / */}
          </NavItem>
          <NavItem>
            <NavLink to="/clients"><FaUsers /> Clients</NavLink> {/* Link to /clients */}
          </NavItem>
        </NavList>
      </nav>
    </div>
    <LogoutButton onClick={onLogout}>
      <FaSignOutAlt /> Logout
    </LogoutButton>
  </SidebarContainer>
);

export default Sidebar;
