// src/components/dashboard.js
import React from 'react';
import styled from 'styled-components';
import Card from './card';

const DashboardContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  height: calc(100vh - 60px); // Adjust height based on the navbar and padding (assuming navbar height is 60px)
  overflow-y: auto; // Ensure the dashboard can scroll if needed
`;

const Dashboard = () => (
  <DashboardContainer>
    <Card title="Revenue" content="IDR 7.852.000" />
    <Card title="Order Time" content="Afternoon: 1,890 orders" />
    <Card title="Your Rating" content="85% Hygiene, 85% Food Taste, 92% Packaging" />
    <Card title="Most Ordered Food" content="Fresh Salad Bowl, Chicken Noodles, Smoothie Fruits" />
    <Card title="Order" content="2,568 orders" />
  </DashboardContainer>
);

export default Dashboard;
