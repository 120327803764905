import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumbsContainer = styled.div`
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: #007bff;
  margin-right: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const NonClickableBreadcrumb = styled.span`
  color: #6c757d;
  margin-right: 5px;
`;

const BreadcrumbSeparator = styled.span`
  margin-right: 5px;
  color: #6c757d;
`;

const breadcrumbNameMap = {
  '/clients': 'Clients',
  '/clients/create-client': 'Add Client',
};

const Breadcrumbs = () => {
  const location = useLocation();
  const state = location.state || {};
  const pathnames = location.pathname.split('/').filter(x => x);

  // Don't render breadcrumbs if there are no path elements
  if (pathnames.length < 1) {
    return null;
  }

  const breadcrumbs = pathnames.map((value, index) => {
    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
    const name = to.includes('/clients/') && state.clientName ? state.clientName : breadcrumbNameMap[to] || value;

    return {
      breadcrumb: name,
      to,
    };
  });

  return (
    <BreadcrumbsContainer>
      {breadcrumbs.map(({ breadcrumb, to }, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return isLast ? (
          <NonClickableBreadcrumb key={to}>
            {breadcrumb}
          </NonClickableBreadcrumb>
        ) : (
          <React.Fragment key={to}>
            <BreadcrumbLink to={to}>{breadcrumb}</BreadcrumbLink>
            <BreadcrumbSeparator>{'>'}</BreadcrumbSeparator>
          </React.Fragment>
        );
      })}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
