import React, { useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addClientCredit } from '../services/api'; // Import the API method for adding credit

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const PopupTitle = styled.h2`
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  margin: 0 5px;

  &:disabled {
    cursor: not-allowed;
  }
`;

const UpdateButton = styled(Button)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #6c757d;
  }
`;

const CancelButton = styled(Button)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;

const AddCreditPopup = ({ clientId, onClose }) => {
  const [credit, setCredit] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setCredit(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addClientCredit(clientId, { credits: parseFloat(credit) });
      toast.success('Credit added successfully');
      setLoading(false);
      onClose(); // Close the popup after success toast
    } catch (error) {
      toast.error(error.response?.data?.displayMessage ?? 'Something Went Wrong');
      setLoading(false);
    }
  };

  return (
    <PopupOverlay>
      <PopupContainer>
        <ToastContainer />
        <PopupTitle>Add Credit</PopupTitle>
        <Form onSubmit={handleSubmit}>
          <Label>Credit Amount</Label>
          <Input
            type="number"
            name="credit"
            value={credit}
            onChange={handleChange}
            required
            step="0.01"
          />
          <ButtonGroup>
            <UpdateButton type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update'}
            </UpdateButton>
            <CancelButton type="button" onClick={onClose}>
              Cancel
            </CancelButton>
          </ButtonGroup>
        </Form>
      </PopupContainer>
    </PopupOverlay>
  );
};

export default AddCreditPopup;
