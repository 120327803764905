// src/components/card.js
import React from 'react';
import styled from 'styled-components';

const CardContainer = styled.div`
  background: #fff;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const CardTitle = styled.h2`
  margin: 0 0 10px;
`;

const CardContent = styled.p`
  margin: 0;
`;

const Card = ({ title, content }) => (
  <CardContainer>
    <CardTitle>{title}</CardTitle>
    <CardContent>{content}</CardContent>
  </CardContainer>
);

export default Card;
