import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/sidebar';
import Navbar from './components/navbar';
import Dashboard from './components/dashboard';
import Login from './components/login';
import ClientList from './components/ClientList';
import ClientCreation from './components/ClientCreation';
import ClientDetails from './components/ClientDetails'; // Import the ClientDetails component
import styled from 'styled-components';
import Breadcrumbs from './components/Breadcrumbs';

const AppContainer = styled.div`
  display: flex;
  height: 100vh;  // Ensure the app container takes the full viewport height
  overflow: hidden;  // Prevent extra scrollbars
`;

const ContentArea = styled.div`
  padding: 20px;
`;

const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;  // Allow the main content to scroll if needed
`;

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true); 
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/" /> : <Login onLogin={handleLogin} />}
        />
        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <AppContainer>
                <Sidebar onLogout={handleLogout} />
                <MainContent>
                  <Navbar />
                  <Breadcrumbs /> {/* Add the Breadcrumbs component */}
                  <ContentArea>
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="/clients" element={<ClientList />} />
                      <Route path="/clients/create-client" element={<ClientCreation />} /> {/* Ensure the correct path */}
                      <Route path="/clients/:clientId" element={<ClientDetails />} /> {/* Add route for ClientDetails */}
                    </Routes>
                  </ContentArea>
                </MainContent>
              </AppContainer>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? "/" : "/login"} />}
        />
      </Routes>
    </Router>
  );
};

export default App;


